
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../helpers/analytics'
import { UpgradeTrigger } from '../helpers/upgrade-triggers'
import { OrganizationModule } from '../store'

@Component({
  components: {
    Menu
  },
  name: 'OrganizationUpgradeMenu'
})
export default class OrganizationUpgradeMenu extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly trigger!: UpgradeTrigger

  @Prop() readonly activator!: () => HTMLElement | undefined
  @Prop({ default: false, type: Boolean }) readonly centerX!: boolean
  @Prop() readonly attach!: any
  @Prop({ default: 100 }) readonly zIndex?: number

  @Prop({ default: false, type: Boolean }) readonly closeOnContentClick!: boolean
  @Prop() readonly closeOnClick: boolean | undefined
  @Prop() readonly openOnClick: boolean | undefined
  @Prop() readonly openOnHover: boolean | undefined
  @Prop() readonly openDelay: number | undefined

  @Prop() readonly left: boolean | undefined
  @Prop() readonly bottom: boolean | undefined
  @Prop() readonly right: boolean | undefined
  @Prop() readonly top: boolean | undefined

  @Prop() readonly offsetX: boolean | undefined
  @Prop() readonly offsetY: boolean | undefined

  @Prop() readonly nudgeBottom!: string | number
  @Prop() readonly nudgeLeft!: string | number
  @Prop() readonly nudgeRight!: string | number
  @Prop() readonly nudgeTop!: string | number

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get image () {
    switch (this.trigger) {
      case 'organization-billing-seats':
      case 'organization-user-invite-dialog':
      case 'organization-members-editor':
      case 'organization-user-invite-dialog-editor':
        return require('../assets/features/collaboration.gif')
      case 'landscape-new':
      case 'landscape-import':
      case 'landscape-duplicate':
        return require('../assets/features/landscape.png')
      case 'diagram-group-create':
        return require('../assets/features/diagram-groups.gif')
      case 'domain-create':
        return require('../assets/features/domains.gif')
      case 'tag-picker-tag-group-new':
      case 'tag-toolbar-tag-group-new':
        return require('../assets/features/tags.gif')
      case 'flow-create':
      case 'flow-editor-create':
      case 'flow-editor-name-picker-create':
        return require('../assets/features/flows.gif')
      case 'flow-editor-create-path':
        return require('../assets/features/flow-paths.gif')
      case 'version-menu-list-info':
      case 'version-timeline':
      case 'version-menu-create-info':
        return require('../assets/features/versions.gif')
      case 'reality-links':
      case 'reality-links-per-item':
        return require('../assets/features/reality-links.gif')
      case 'version-menu-freeze-object':
        return require('../assets/features/version-objects.gif')
      case 'team-new':
      case 'organization-ownership-teams':
        return require('../assets/features/teams.gif')
      case 'diagram-draft-create':
        return require('../assets/features/draft-diagram.gif')
    }
  }

  get imageAspectRatio () {
    switch (this.trigger) {
      case 'diagram-group-create':
        return 1.839
      case 'flow-editor-create-path':
        return 1.895
      case 'diagram-draft-create':
        return 1.847
      case 'organization-billing-seats':
      case 'organization-user-invite-dialog':
      case 'organization-members-editor':
      case 'organization-user-invite-dialog-editor':
      case 'landscape-new':
      case 'landscape-import':
      case 'landscape-duplicate':
      case 'domain-create':
      case 'tag-picker-tag-group-new':
      case 'tag-toolbar-tag-group-new':
      case 'flow-create':
      case 'flow-editor-create':
      case 'flow-editor-name-picker-create':
      case 'version-menu-list-info':
      case 'version-timeline':
      case 'version-menu-create-info':
      case 'reality-links':
      case 'reality-links-per-item':
      case 'version-menu-freeze-object':
      case 'team-new':
      case 'organization-ownership-teams':
        return 1.777
      default: return 1
    }
  }

  get title () {
    switch (this.trigger) {
      case 'organization-billing-seats':
      case 'organization-user-invite-dialog':
        return 'Upgrade to invite more people'
      case 'organization-members-editor':
      case 'organization-user-invite-dialog-editor':
        return 'Upgrade to invite more editors'
      case 'landscape-new':
      case 'landscape-import':
      case 'landscape-duplicate':
        return 'Upgrade to get unlimited landscapes'
      case 'diagram-group-create':
        return 'Upgrade to create diagram groups'
      case 'domain-create':
        return 'Upgrade to create domains'
      case 'tag-picker-tag-group-new':
      case 'tag-toolbar-tag-group-new':
        return 'Upgrade to create unlimited tag groups'
      case 'flow-create':
      case 'flow-editor-create':
      case 'flow-editor-name-picker-create':
        return 'Upgrade to create unlimited flows'
      case 'flow-editor-create-path':
        return 'Upgrade to create flow paths'
      case 'version-menu-list-info':
      case 'version-timeline':
      case 'version-menu-create-info':
        return `Upgrade to view more than the last ${this.currentOrganizationLimits.versions} versions`
      case 'reality-links':
        return 'Upgrade to add more reality links'
      case 'reality-links-per-item':
        return 'Upgrade to add multiple reality links'
      case 'version-menu-freeze-object':
        return 'Upgrade to version objects'
      case 'team-new':
      case 'organization-ownership-teams':
        return 'Upgrade to assign object ownership'
      case 'model-object-create':
        return 'Upgrade to create unlimited model objects'
      case 'diagram-draft-create':
        return 'Upgrade to create multiple drafts per diagram'
      default: return 'Upgrade'
    }
  }

  get description () {
    switch (this.trigger) {
      case 'organization-billing-seats':
      case 'organization-user-invite-dialog':
        return 'Collaborate with your teammates and create a consistent shared knowledge base'
      case 'organization-members-editor':
      case 'organization-user-invite-dialog-editor':
        return 'Collaborate with your teammates and create a consistent shared knowledge base - better together. Editors can make changes to diagrams and documentation.'
      case 'landscape-new':
      case 'landscape-import':
      case 'landscape-duplicate':
        return 'A landscape uses a single model to draw many diagrams; visualizing the systems spanning across your organization.'
      case 'diagram-group-create':
        return 'Groups for organizing many diagrams by subject'
      case 'domain-create':
        return 'Domains organize your landscape\'s systems into logical groups aligned with your business structure. You can share objects in diagrams between domains.'
      case 'tag-picker-tag-group-new':
      case 'tag-toolbar-tag-group-new':
        return 'Dynamically overlay different perspectives on your existing diagrams and filter your model by tags'
      case 'flow-create':
      case 'flow-editor-create':
      case 'flow-editor-name-picker-create':
        return 'Dynamically overlay sequences of messages on existing diagrams to showcase technical, user or business flows'
      case 'flow-editor-create-path':
        return 'Show alternate choices in a message flow'
      case 'version-menu-list-info':
      case 'version-menu-create-info':
        return 'Visually follow the evolution of your system design across time'
      case 'version-timeline':
        return 'Visualize the differences between versions of your system design and easily follow the evolution across time'
      case 'reality-links':
      case 'reality-links-per-item':
        return 'List important resources in your source control and quickly get to them - great for onboarding. We periodically check these links and mark them as inaccurate if missing.'
      case 'version-menu-freeze-object':
        return 'Manage the lifecycle of each domain, system, app and store independently with hierarchical versioning'
      case 'team-new':
      case 'organization-ownership-teams':
        return 'Share who your system experts are by assigning owners to model objects and contact them easily'
      case 'diagram-draft-create':
        return 'Design future versions of your architecture in isolation and merge the changes to into the current model once approved.'
      default: return ''
    }
  }

  get button () {
    if (this.currentOrganization) {
      if ((this.currentOrganization.plan === 'free' || this.currentOrganization.plan === 'indie') && !this.currentOrganization.trialEndsAt) {
        return {
          icon: '$fas-bolt',
          text: 'Start trial'
        }
      } else if (this.currentOrganization.plan === 'free' || this.currentOrganization.plan === 'indie' || this.currentOrganization.status === 'trialing') {
        return {
          icon: '$fas-bolt',
          text: 'Upgrade'
        }
      }
    }
  }

  opened () {
    analytics.organizationUpgradeMenu.track(this, {
      organizationId: this.currentOrganization ? [this.currentOrganization.id] : [],
      organizationUpgradeTrigger: this.trigger
    })
  }
}
