
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import LandscapeListItems from '@/modules/landscape/components/dropdown/list-items.vue'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../../helpers/analytics'
import OrganizationListItems from './list-items.vue'

@Component({
  components: {
    LandscapeListItems,
    Menu,
    OrganizationListItems
  },
  name: 'OrganizationListMenu'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)

  @Ref() readonly menuRef!: Menu
  @Ref() readonly landscapeListItemsRef!: HTMLElement
  @Prop() readonly activator!: (() => HTMLElement | undefined) | undefined

  selectedOrganizationId: string = ''

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  open () {
    this.selectedOrganizationId = this.currentOrganizationId
  }

  opened () {
    analytics.organizationListMenu.track(this, {
      organizationId: [this.currentOrganizationId]
    })
    this.$nextTick(() => {
      const { $el } = this.landscapeListItemsRef as any
      $el.focus()
    })
  }

  closed () {
    this.selectedOrganizationId = ''
  }
}
