
import { OrganizationBillingCurrency, OrganizationBillingCycle, OrganizationPlan } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import * as appAnalytics from '@/helpers/analytics'
import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { UpgradeTrigger } from '../helpers/upgrade-triggers'
import { OrganizationModule } from '../store'

const CHECK_ICON = {
  icon: '$fas-check',
  iconColor: 'grey lighten-2'
} as const

const CROSS_ICON = {
  icon: '$custom-solid-horizontal-rule',
  iconColor: 'grey lighten-4'
} as const

const UNLIMITED_TEXT = {
  text: 'Unlimited'
} as const

const CURRENCIES: { symbol: string, id: OrganizationBillingCurrency, image: string }[] = [
  {
    id: 'usd',
    image: require('../assets/flags/us.png'),
    symbol: '$'
  },
  {
    id: 'cad',
    image: require('../assets/flags/ca.png'),
    symbol: '$'
  },
  {
    id: 'eur',
    image: require('../assets/flags/eu.png'),
    symbol: '€'
  },
  {
    id: 'gbp',
    image: require('../assets/flags/gb.png'),
    symbol: '£'
  }
]

const GROWTH_PRICES: Record<NonNullable<OrganizationBillingCycle>, Record<OrganizationBillingCurrency, number>> = {
  annually: {
    cad: 55,
    eur: 36,
    gbp: 32,
    usd: 40
  },
  monthly: {
    cad: 68,
    eur: 45,
    gbp: 40,
    usd: 50
  }
}

const GROWTH_PRICES_AFTER_THREASHOLD: Record<NonNullable<OrganizationBillingCycle>, Record<OrganizationBillingCurrency, number>> = {
  annually: {
    cad: 16,
    eur: 11,
    gbp: 10,
    usd: 12
  },
  monthly: {
    cad: 20,
    eur: 13,
    gbp: 11,
    usd: 14
  }
}

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationUpgradeDialog'
})
export default class OrganizationUpgradeDialog extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  creatingSubscriptionLink = false
  loadingBillingLink = false
  updatingSubscription = false
  creatingSubscription = false
  updatingOrganizationBillingCycle = false
  updatingOrganizationBillingCurrency = false

  trigger: UpgradeTrigger = 'appbar'
  mode: 'pricing' | 'features' = 'pricing'

  isOpen = false

  seatCount = 5
  seatCountModel = 5

  growthSeatCountMin = 1
  growthSeatCountMax = 100
  growthDiscountThreshold = 15

  billingCurrency: OrganizationBillingCurrency = 'usd'
  billingCycle: OrganizationBillingCycle = 'monthly'

  get billingCurrencyCanChange () {
    return this.currentOrganization?.plan === 'free' || this.currentOrganization?.status === 'trialing'
  }

  get signUpBillingCurrency () {
    return this.$queryValue('billing_currency') as OrganizationBillingCurrency | null
  }

  get signUpBillingCycle () {
    return this.$queryValue('billing_cycle') as OrganizationBillingCycle | null
  }

  get signUpSeats () {
    const seats = this.$queryValue('seats')
    return seats ? parseInt(seats) : undefined
  }

  get localeString () {
    switch (this.billingCurrency) {
      case 'cad':
        return 'en-CA'
      case 'eur':
        return 'de-DE'
      case 'gbp':
        return 'en-GB'
      case 'usd':
        return 'en-US'
      default:
        return 'en-US'
    }
  }

  get getGrowthPlanDiscountPerUser () {
    return GROWTH_PRICES_AFTER_THREASHOLD[this.billingCycle][this.billingCurrency].toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getGrowthPlanPriceWithoutDiscount () {
    const growthPriceWithoutDiscount = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.seatCount
    return growthPriceWithoutDiscount.toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getGrowthPlanSavings () {
    const growthPriceWithoutDiscount = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.seatCount
    const growthPrice = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.growthDiscountThreshold
    const growthDiscountPrice = GROWTH_PRICES_AFTER_THREASHOLD[this.billingCycle][this.billingCurrency] * (this.seatCount - this.growthDiscountThreshold)
    return (growthPriceWithoutDiscount - (growthPrice + growthDiscountPrice)).toLocaleString(this.localeString, {
      currency: this.billingCurrency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    })
  }

  get getGrowthPlanUserCountDiscountPriceTotal () {
    if (this.seatCount > this.growthDiscountThreshold) {
      // more than 15 seats selected so return the normal price for the first 15 and then the discounted price for the remaining seats
      const growthPrice = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.growthDiscountThreshold
      const growthDiscountPrice = GROWTH_PRICES_AFTER_THREASHOLD[this.billingCycle][this.billingCurrency] * (this.seatCount - this.growthDiscountThreshold)
      return (growthPrice + growthDiscountPrice).toLocaleString(this.localeString, {
        currency: this.billingCurrency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency'
      })
    } else {
      // less than 15 seats selected so just return the normal price
      const growthPrice = GROWTH_PRICES[this.billingCycle][this.billingCurrency] * this.seatCount
      return growthPrice.toLocaleString(this.localeString, {
        currency: this.billingCurrency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency'
      })
    }
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get currentTrigger () {
    return this.organizationUpgradeDialog?.split('_')[0] as UpgradeTrigger | undefined
  }

  get currentMode () {
    const mode = this.organizationUpgradeDialog?.split('_')[1]
    if (mode === 'pricing') {
      return 'pricing'
    } else if (mode === 'features') {
      return 'features'
    }
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentOrganizationSeats () {
    return this.currentOrganization ? this.currentOrganization.seats : undefined
  }

  get currencies () {
    return CURRENCIES
  }

  get currentCurrency () {
    return CURRENCIES.find(o => o.id === this.billingCurrency)!
  }

  get currentSeats () {
    return this.currentOrganization?.seats
  }

  get plans () {
    let growthActionPrimary: any | undefined
    let growthActionSecondary: any | undefined

    const unauthorized = this.currentOrganizationPermission !== 'admin' && this.currentOrganizationPermission !== 'billing'

    if (this.currentOrganization?.status === 'trialing' && this.currentOrganization.cancelAt) {
      growthActionPrimary = {
        caption: 'Cancellation scheduled at the end of trial',
        click: () => this.createBillingLink(),
        disabled: unauthorized,
        icon: '$fas-pencil-alt',
        text: 'Modify',
        tooltip: unauthorized ? 'You must be an organization admin or billing' : undefined
      }
    } else if (this.currentOrganization?.status === 'trialing' && this.currentOrganization.billingPaymentMethod) {
      growthActionPrimary = {
        caption: `${this.currentOrganization.seats} seat${this.currentOrganization.seats === 1 ? '' : 's'} charged ${this.billingCycle} after trial ends`,
        click: () => this.updateSubscription({
          seats: this.seatCount
        }),
        disabled: unauthorized || this.currentOrganizationSeats === this.seatCount,
        icon: '$fas-pencil-alt',
        text: 'Modify editor count',
        tooltip: unauthorized ? 'You must be an organization admin or billing' : this.currentOrganizationSeats === this.seatCount ? 'Editor count already applied' : undefined
      }
    } else if (this.currentOrganization?.status === 'trialing') {
      growthActionPrimary = {
        caption: `${this.seatCount} seat${this.seatCount === 1 ? '' : 's'} charged ${this.billingCycle} after trial ends`,
        click: async () => {
          await this.updateSubscription({
            seats: this.seatCount
          })
          await this.createBillingLink('payment-methods')
        },
        disabled: unauthorized,
        icon: '$fas-lock',
        text: 'Add payment details',
        tooltip: unauthorized ? 'You must be an organization admin or billing' : undefined
      }
    } else if (this.currentOrganization?.plan === 'free' && !this.currentOrganization.trialEndsAt) {
      growthActionPrimary = {
        caption: 'No payment method required',
        click: () => this.createSubscription({
          billingCycle: 'monthly',
          trial: true
        }),
        disabled: unauthorized,
        icon: '$custom-solid-sparkles',
        text: 'Start free 14 day trial',
        tooltip: unauthorized ? 'You must be an organization admin or billing' : 'Trial includes 5 editors'
      }
      growthActionSecondary = {
        click: () => this.createSubscriptionLink({
          plan: 'growth',
          seats: this.seatCount
        }),
        disabled: unauthorized,
        icon: '$fas-lock',
        text: 'Buy',
        tooltip: unauthorized ? 'You must be an organization admin or billing' : `Buy ${this.seatCount} editor${this.seatCount === 1 ? '' : 's'}`
      }
    } else if (this.currentOrganization?.plan === 'free') {
      growthActionPrimary = {
        caption: this.currentOrganization.trialEndsAt ? '14 day trial already used' : undefined,
        click: () => this.createSubscriptionLink({
          plan: 'growth',
          seats: this.seatCount
        }),
        disabled: unauthorized,
        icon: '$fas-lock',
        text: 'Upgrade',
        tooltip: unauthorized ? 'You must be an organization admin or billing' : undefined
      }
    }

    return [
      {
        caption: 'Start modelling in one team',
        glow: false,
        id: 'free',
        price: {
          caption: '/ month',
          symbol: this.currentCurrency.symbol,
          value: 0
        },
        seats: {
          max: 5,
          min: 1
        },
        summary: {
          caption: 'Free includes:',
          list: [
            {
              text: 'C4 modelling & diagrams',
              tooltip: 'Zoom in and out of the C4 model diagram levels, easily exploring the big picture to the details.'
            },
            {
              text: 'Up to 100 model objects',
              tooltip: 'Create up to 100 objects inside your model and draw them as many times as needed.'
            },
            {
              text: 'Public share links',
              tooltip: 'Share and embed public interactive read-only public links of your landscape and diagrams.'
            },
            {
              text: 'Unlimited share viewers',
              tooltip: 'Unlimited read-only users can access share links of your landscape, diagrams and flows.'
            }
          ]
        },
        text: 'Free'
      },
      {
        actionPrimary: growthActionPrimary,
        actionSecondary: growthActionSecondary,
        caption: 'Collaborate on large models across multiple teams',
        glow: true,
        id: 'growth',
        price: {
          caption: '/ month',
          symbol: this.currentCurrency.symbol,
          value: GROWTH_PRICES[this.billingCycle][this.billingCurrency]
        },
        seats: {
          max: this.growthSeatCountMax,
          min: this.growthSeatCountMin
        },
        summary: {
          caption: 'Everything in Free, plus:',
          list: [
            {
              text: 'Unlimited modelling',
              tooltip: 'No limits on landscapes, model objects, tags, flows, reality links and versions.'
            },
            {
              text: 'Domains for large models',
              tooltip: 'Domains organize your landscape\'s systems into logical groups aligned with your business structure. You can share objects in diagrams between domains..'
            },
            {
              text: 'Diagram groups',
              tooltip: 'Groups for organizing many diagrams by subject.'
            },
            {
              text: 'Assign object owners',
              tooltip: 'Share who your system experts are by assigning owners to model objects, and contact them easily.'
            },
            {
              text: 'Object versioning',
              tooltip: 'Manage the lifecycle of each domain, system, app and store independently with hierarchical versioning.'
            },
            {
              text: 'Protected share links',
              tooltip: 'Share and embed password or SSO protected interactive read-only links of your landscape and diagrams.'
            },
            {
              text: 'Single sign-on',
              tooltip: 'Manage your team’s log in authentication with SAML 2.0 SSO.'
            },
            {
              text: 'Premium support',
              tooltip: 'Email & Slack < 48 hours'
            }
          ]
        },
        text: 'Growth'
      },
      {
        actionPrimary: {
          click: () => this.isolationPriceCalculatorLink(),
          icon: '$fas-calculator',
          text: 'Calculate price'
        },
        caption: 'Single-tenant environment for custom data security',
        glow: false,
        id: 'isolation',
        price: {
          caption: 'Custom pricing'
        },
        seats: {
          min: 50
        },
        summary: {
          caption: 'Everything in Growth, plus:',
          list: [
            {
              text: 'Data residency option',
              tooltip: 'Choose a custom Google Cloud region for your environment and data storage.'
            },
            {
              text: 'IP allow/block list',
              tooltip: 'Restrict access of your IcePanel environment to specific IP range or corporate network.'
            },
            {
              text: 'Environment audit logs',
              tooltip: 'Receive full database, authentication and environment-wide audit logs.'
            },
            {
              text: 'Extra security parameters',
              tooltip: 'We will accommodate extra security parameters you need to configure.'
            },
            {
              text: 'Customer success program',
              tooltip: 'Dedicated customer success manager to upskill your team to ensure you get the most out of the tool.'
            },
            {
              text: 'Priority support',
              tooltip: 'Email, Slack & Phone < 24 hours'
            }
          ]
        },
        text: 'Isolation'
      }
    ] as const
  }

  get features () {
    return [
      {
        icon: '$fas-sitemap',
        id: 'modelling',
        list: [
          {
            description: 'Zoom in and out of the C4 model diagram levels, easily exploring the big picture to the details.',
            image: require('../assets/features/zoom.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '100 model objects'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Model and diagrams'
          },
          {
            description: 'A landscape uses a single model to draw many diagrams; visualizing the systems spanning across your organization.',
            image: require('../assets/features/landscape.png'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'Up to 2'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Landscapes'
          },
          {
            description: 'Groups for organizing many diagrams by subject.',
            image: require('../assets/features/diagram-groups.gif'),
            imageAspectRatio: 1.839,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Diagram groups'
          },
          {
            description: 'Domains organize your landscape\'s systems into logical groups aligned with your business structure. You can share objects in diagrams between domains.',
            image: require('../assets/features/domains.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Domains'
          },
          {
            description: 'Design future versions of your architecture in isolation and merge the changes to into the current model once approved.',
            image: require('../assets/features/draft-diagram.gif'),
            imageAspectRatio: 1.847,
            plans: {
              free: {
                text: '1 draft per diagram'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Draft designs'
          },
          {
            description: 'Attach rich documentation to model objects, connections and diagrams to give more context to your audience - supports Markdown.',
            image: require('../assets/features/documentation.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Documentation'
          },
          {
            description: 'Updates to your model push change across all diagrams, making design refactoring effortless.',
            image: require('../assets/features/sync-model-changes.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Sync model changes'
          },
          {
            description: 'Visually follow the evolution of your system design across time.',
            image: require('../assets/features/versions.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'View last 3 versions'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Versions'
          },
          {
            description: 'Revert back to a point in the past with version reverting.',
            image: require('../assets/features/version-revert.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Version revert'
          },
          {
            description: 'Manage the lifecycle of each domain, system, app and store independently with hierarchical versioning.',
            image: require('../assets/features/version-objects.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Versions for objects'
          },
          {
            description: 'Export your objects, connections, diagrams, flows, tags and teams in PNG, PDF, JSON or CSV formats',
            image: require('../assets/features/exports.webp'),
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Exports'
          }
        ],
        text: 'Modelling'
      },
      {
        icon: '$fas-layer-group',
        id: 'visual-storytelling',
        list: [
          {
            description: 'Dynamically overlay different perspectives on your existing diagrams and filter your model by tags.',
            image: require('../assets/features/tags.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '2 tag groups'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Tags'
          },
          {
            description: 'Dynamically overlay sequences of messages on existing diagrams to showcase technical, user or business flows.',
            image: require('../assets/features/flows.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '3 flows'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Flows'
          },
          {
            description: 'Show alternate and parallel choices in a message flow',
            image: require('../assets/features/flow-paths.gif'),
            imageAspectRatio: 1.895,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Flow paths'
          },
          {
            description: 'Add AWS, Azure, GCP and Kubernetes icons to visualize the technology choice of your apps and stores.',
            image: require('../assets/features/cloud-icons.png'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Cloud icons'
          },
          {
            description: 'Visualize the differences between versions of your system design and easily follow the evolution across time.',
            image: require('../assets/features/versions.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Version timeline'
          }
        ],
        text: 'Visual storytelling'
      },
      {
        icon: '$fas-calendar-check',
        id: 'up-to-date-checks',
        list: [
          {
            description: 'List important resources in your source control and quickly get to them - great for onboarding. We periodically check reality links and mark them as inaccurate if missing.',
            image: require('../assets/features/reality-links.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: '10 links - 1 per object'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'Links'
          },
          {
            description: 'Link to important resources in your source control and quickly get to them - great for onboarding. We periodically check these links and mark them as inaccurate if missing.\n\nSupports: GitHub, GitLab, Bitbucket Server and Azure DevOps',
            icons: [
              '$fab-github',
              '$fab-gitlab',
              '$fab-bitbucket',
              '$fab-windows'
            ],
            image: require('../assets/features/code-repo-integration.png'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Code repo integrations'
          },
          {
            description: 'See where your design drifts from reality and take action to resolve the inaccuracies.',
            image: require('../assets/features/accuracy-score.png'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Inaccuracy score'
          },
          {
            description: 'See where your documentation could be improved and resolve common diagramming mistakes.',
            image: require('../assets/features/recommendation-score.png'),
            imageAspectRatio: 1.777,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Recommendation score'
          }
        ],
        text: 'Up-to-date checks'
      },
      {
        icon: '$fas-users',
        id: 'collaboration',
        list: [
          {
            description: 'Collaborate with your teammates and create a consistent shared knowledge base - better together.',
            image: require('../assets/features/collaboration.gif'),
            imageAspectRatio: 1.776,
            plans: {
              free: {
                text: '1 - 5'
              },
              growth: {
                text: '1 - 100'
              },
              isolation: {
                text: '50+'
              }
            },
            text: 'Editors'
          },
          {
            description: 'Collaborate with your teammates and create a consistent shared knowledge base - better together.',
            image: require('../assets/features/collaboration.gif'),
            imageAspectRatio: 1.776,
            plans: {
              free: {
                text: 'Unlimited'
              },
              growth: {
                text: 'Unlimited'
              },
              isolation: {
                text: 'Unlimited'
              }
            },
            text: 'Viewers'
          },
          {
            description: 'Share and embed interactive read-only links of your landscape and diagrams.',
            image: require('../assets/features/share-links.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'Public'
              },
              growth: {
                text: 'Password + SSO'
              },
              isolation: {
                text: 'Password + SSO'
              }
            },
            text: 'Share links'
          },
          {
            description: 'Share interactive frozen versions of your design as it existed in the past.',
            image: require('../assets/features/share-link-versions.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Share links for versions'
          },
          {
            description: 'Share who your system experts are by assigning owners to model objects and contact them easily.',
            image: require('../assets/features/teams.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Team ownership for objects'
          },
          {
            description: 'See a detailed logs of the changes happening in your landscape.',
            image: require('../assets/features/history-logs.gif'),
            imageAspectRatio: 1.777,
            plans: {
              free: {
                text: 'Last 30 days'
              },
              growth: UNLIMITED_TEXT,
              isolation: UNLIMITED_TEXT
            },
            text: 'History logs'
          },
          {
            description: 'Collaborate in real time with your colleagues.',
            image: require('../assets/features/collaboration.gif'),
            imageAspectRatio: 1.776,
            plans: {
              free: CHECK_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Real time'
          }
        ],
        text: 'Collaboration'
      },
      {
        icon: '$fas-hand-holding-heart',
        id: 'support',
        list: [
          {
            description: 'Contact us with queries, feedback and support requests. Our virtual door is always open.',
            plans: {
              free: {
                text: 'Email'
              },
              growth: {
                text: 'Email & Slack'
              },
              isolation: {
                text: 'Email, Slack & Phone'
              }
            },
            text: 'Support channels'
          },
          {
            description: 'We respond to your queries, feedback and support requests as soon as possible.',
            plans: {
              free: {
                text: '< 5 days'
              },
              growth: {
                text: '< 48 hours'
              },
              isolation: {
                text: '< 24 hours'
              }
            },
            text: 'Response time'
          },
          {
            description: 'Manage your team\'s logins with SAML 2.0 single sign-on.',
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Single sign-on'
          },
          {
            description: 'Automatically add users registering with your company domain to your organization .',
            plans: {
              free: CROSS_ICON,
              growth: CHECK_ICON,
              isolation: CHECK_ICON
            },
            text: 'Company email auto invite'
          },
          {
            description: 'Flexible payment options with annual invoice billing.',
            plans: {
              free: CROSS_ICON,
              growth: {
                text: 'For 10+ editors'
              },
              isolation: CHECK_ICON
            },
            text: 'Invoice billing'
          },
          {
            description: 'Master services agreement (MSA) customized to your legal and technical requirements.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Master services agreement'
          },
          {
            description: 'Choose a custom Google Cloud region for your environment and data storage.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Data residency option'
          },
          {
            description: 'Restrict access of your IcePanel environment to specific IP range or corporate network.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'IP allow/block list'
          },
          {
            description: 'Receive full database, authentication and environment-wide audit logs.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Environment audit logs'
          },
          {
            description: 'We will accommodate extra security parameters you need to configure.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Extra security parameters'
          },
          {
            description: 'Dedicated customer success manager to upskill your team to ensure you get the most out of the tool.',
            plans: {
              free: CROSS_ICON,
              growth: CROSS_ICON,
              isolation: CHECK_ICON
            },
            text: 'Customer success program'
          }
        ],
        text: 'Support'
      }
    ]
  }

  @Watch('currentTrigger')
  onCurrentTriggerChanged (currentTrigger?: UpgradeTrigger) {
    if (currentTrigger) {
      this.trigger = currentTrigger
    }
  }

  @Watch('currentMode')
  onCurrentModeChanged (currentMode?: 'pricing' | 'features') {
    if (currentMode && this.mode !== currentMode) {
      this.mode = currentMode

      if (this.isOpen && this.currentOrganization) {
        if (currentMode === 'pricing') {
          analytics.organizationUpgradePricingDialog.track(this, {
            organizationId: [this.currentOrganization.id],
            organizationUpgradeTrigger: this.trigger
          })
        } else if (currentMode === 'features') {
          analytics.organizationUpgradeFeaturesDialog.track(this, {
            organizationId: [this.currentOrganization.id],
            organizationUpgradeTrigger: this.trigger
          })
        }
      }
    }
  }

  get defaultSeatCount () {
    return this.currentOrganization && this.currentOrganizationSeats && (this.currentOrganization.plan !== 'free' || this.currentOrganization.status === 'trialing') ? this.currentOrganizationSeats : 5
  }

  mounted () {
    if (this.currentTrigger) {
      this.trigger = this.currentTrigger
    }
    if (this.currentMode) {
      this.mode = this.currentMode
    }
  }

  open () {
    this.billingCurrency = this.signUpBillingCurrency || this.currentOrganization?.billingCurrency || 'usd'
    this.billingCycle = this.signUpBillingCycle || this.currentOrganization?.billingCycle || 'monthly'

    this.seatCount = this.signUpSeats || this.defaultSeatCount
    this.seatCountModel = this.seatCount
  }

  opened () {
    this.billingCurrency = this.signUpBillingCurrency || this.currentOrganization?.billingCurrency || 'usd'
    this.billingCycle = this.signUpBillingCycle || this.currentOrganization?.billingCycle || 'monthly'

    this.seatCount = this.signUpSeats || this.defaultSeatCount
    this.seatCountModel = this.seatCount

    if (this.currentOrganization) {
      if (this.mode === 'pricing') {
        analytics.organizationUpgradePricingDialog.track(this, {
          organizationId: [this.currentOrganization.id],
          organizationUpgradeTrigger: this.trigger
        })
      } else if (this.mode === 'features') {
        analytics.organizationUpgradeFeaturesDialog.track(this, {
          organizationId: [this.currentOrganization.id],
          organizationUpgradeTrigger: this.trigger
        })
      }
    }

    this.isOpen = true
  }

  close () {
    this.isOpen = false
  }

  closed () {
    this.$replaceQuery({
      billing_currency: undefined,
      billing_cycle: undefined,
      seats: undefined
    })
  }

  validateSeatCount (updateModel: boolean) {
    this.seatCount = Math.max(this.growthSeatCountMin, Math.min(this.growthSeatCountMax, this.seatCountModel))

    if (updateModel) {
      this.seatCountModel = this.seatCount
    }
  }

  async updateOrganizationBillingCurrency (billingCurrency: OrganizationBillingCurrency) {
    if (this.currentOrganization && this.billingCurrencyCanChange && this.billingCurrency !== billingCurrency) {
      try {
        this.updatingOrganizationBillingCurrency = true
        this.billingCurrency = billingCurrency

        await this.organizationModule.updateOrganization({
          organizationId: this.currentOrganization.id,
          props: {
            billingCurrency
          }
        })
      } finally {
        this.updatingOrganizationBillingCurrency = false
      }
    }
  }

  async updateOrganizationBillingCycle (billingCycle: OrganizationBillingCycle) {
    if (this.currentOrganization && this.billingCycle !== billingCycle) {
      try {
        this.updatingOrganizationBillingCycle = true
        this.billingCycle = billingCycle

        await this.organizationModule.updateOrganization({
          organizationId: this.currentOrganization.id,
          props: {
            billingCycle
          }
        })
      } finally {
        this.updatingOrganizationBillingCycle = false
      }
    }
  }

  async createSubscription ({ plan, trial, seats, billingCycle }: { trial?: boolean, plan?: Exclude<OrganizationPlan, 'free' | 'isolation'>, seats?: number, billingCycle: OrganizationBillingCycle }) {
    try {
      if (!this.currentOrganization) {
        return
      }
      if (this.creatingSubscription) {
        return
      }
      this.creatingSubscription = true

      let organizationId: string
      if (this.currentOrganization) {
        organizationId = this.currentOrganization.id
      } else {
        const org = await this.organizationModule.organizationCreate({
          billingCurrency: this.billingCurrency,
          billingCycle,
          billingEmail: this.userModule.user?.email,
          name: `${this.userModule.user?.name}'s organization`
        })

        organizationId = org.id
      }

      await this.organizationModule.organizationBillingSubscriptionCreate({
        organizationId,
        subscription: {
          billingCurrency: this.billingCurrency,
          billingCycle,
          plan,
          seats,
          trial
        }
      })

      analytics.organizationBillingSubscriptionCreate.track(this, {
        organizationBillingCurrency: this.billingCurrency,
        organizationBillingCycle: this.billingCycle,
        organizationId: [organizationId],
        organizationPlan: plan ?? 'free',
        organizationSeats: seats ?? 5,
        organizationTrial: !!trial
      })
      analytics.organizationUpgradeSuccess.track(this, {
        organizationId: [this.currentOrganization.id],
        organizationUpgradePlan: plan ?? 'free',
        organizationUpgradeTrial: !!trial,
        organizationUpgradeTrigger: this.trigger
      })

      await this.organizationModule.organizationFind(this.currentOrganization.id)

      await this.$replaceQuery({
        organization_upgrade_dialog: undefined,
        user_invite_dialog: seats && seats >= 4 ? '1' : undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        duration: undefined,
        message: err.body.message
      })
    } finally {
      this.creatingSubscription = false
    }
  }

  async createSubscriptionLink ({ plan, trial, seats }: { trial?: boolean, plan: Exclude<OrganizationPlan, 'free' | 'isolation'>, seats: number }) {
    try {
      if (this.creatingSubscriptionLink) {
        return
      }
      this.creatingSubscriptionLink = true

      let organizationId: string
      if (this.currentOrganization) {
        organizationId = this.currentOrganization.id
      } else {
        const org = await this.organizationModule.organizationCreate({
          billingCurrency: this.billingCurrency,
          billingCycle: this.billingCycle,
          billingEmail: this.userModule.user?.email,
          name: `${this.userModule.user?.name}'s organization`
        })

        organizationId = org.id
      }

      const successUrl = new URL(window.location.href)
      successUrl.searchParams.delete('organization_upgrade_dialog')
      successUrl.searchParams.set('upgrade', organizationId)
      successUrl.searchParams.set('upgrade_result', 'success')
      successUrl.searchParams.set('upgrade_plan', plan)
      successUrl.searchParams.set('upgrade_trigger', this.trigger)
      if (trial) {
        successUrl.searchParams.set('upgrade_trial', 'true')
      }
      if (seats >= 4) {
        successUrl.searchParams.set('user_invite_dialog', '1')
      }

      const cancelUrl = new URL(window.location.href)
      cancelUrl.searchParams.set('upgrade', organizationId)
      cancelUrl.searchParams.set('upgrade_result', 'cancel')
      cancelUrl.searchParams.set('upgrade_plan', plan)
      cancelUrl.searchParams.set('upgrade_trigger', this.trigger)
      if (trial) {
        cancelUrl.searchParams.set('upgrade_trial', 'true')
      }
      if (this.billingCurrency) {
        cancelUrl.searchParams.set('billing_currency', this.billingCurrency)
      }
      if (this.billingCycle) {
        cancelUrl.searchParams.set('billing_cycle', this.billingCycle)
      }
      if (this.seatCount) {
        cancelUrl.searchParams.set('seats', `${this.seatCount}`)
      }

      const url = await this.organizationModule.organizationBillingSubscriptionLinkCreate({
        cancelUrl: cancelUrl.toString(),
        organizationId,
        subscription: {
          billingCurrency: this.billingCurrency,
          billingCycle: trial ? 'monthly' : this.billingCycle,
          plan,
          seats,
          trial
        },
        successUrl: successUrl.toString()
      })

      analytics.organizationBillingSubscriptionLinkCreate.track(this, {
        organizationBillingCurrency: this.billingCurrency,
        organizationBillingCycle: this.billingCycle,
        organizationId: [organizationId],
        organizationPlan: plan,
        organizationSeats: seats,
        organizationTrial: !!trial
      })

      window.location.href = url
    } catch (err: any) {
      this.creatingSubscriptionLink = false
      this.alertModule.pushAlert({
        color: 'error',
        duration: undefined,
        message: err.body?.message || err.message
      })
    }
  }

  async updateSubscription ({ plan, trial, seats }: { trial?: boolean, plan?: Exclude<OrganizationPlan, 'free' | 'isolation'>, seats: number }) {
    try {
      if (!this.currentOrganization) {
        return
      }
      if (this.updatingSubscription) {
        return
      }
      this.updatingSubscription = true

      await this.organizationModule.organizationBillingSubscriptionUpdate({
        organizationId: this.currentOrganization.id,
        update: {
          plan,
          seats,
          trial
        }
      })

      analytics.organizationBillingSubscriptionUpdate.track(this, {
        organizationId: [this.currentOrganization.id],
        organizationPlan: plan,
        organizationSeats: seats,
        organizationTrial: trial
      })

      await this.organizationModule.organizationFind(this.currentOrganization.id)
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        duration: undefined,
        message: err.body?.message || err.message
      })
    } finally {
      this.updatingSubscription = false
    }
  }

  async createBillingLink (action?: 'payment-methods') {
    try {
      if (!this.currentOrganization) {
        return
      }
      if (this.loadingBillingLink) {
        return
      }
      this.loadingBillingLink = true

      const returnUrl = new URL(window.location.href)
      returnUrl.searchParams.set('billing_organization', this.currentOrganization.id)
      returnUrl.searchParams.set('billing_trigger', this.trigger)

      const links = await this.organizationModule.organizationBillingLinkCreate({
        organizationId: this.currentOrganization.id,
        returnUrl: returnUrl.toString()
      })

      analytics.organizationBillingLink.track(this, {
        organizationId: [this.currentOrganization.id]
      })

      await new Promise(resolve => setTimeout(resolve, 500))

      window.location.href = action === 'payment-methods' ? links.paymentMethodsUrl : links.url
    } catch (err: any) {
      this.loadingBillingLink = false
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body?.message || err.message
      })
    }
  }

  isolationPriceCalculatorLink () {
    appAnalytics.isolationPriceCalculatorLink.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : undefined,
      organizationId: this.currentOrganization ? [this.currentOrganization.id] : undefined
    })

    const link = env.IS_PRODUCTION ? 'https://icepanel.io/pricing?isolation_calculator_dialog=1' : 'https://icepanel.dev/pricing?isolation_calculator_dialog=1'
    window.open(link, '_blank')?.focus()
  }
}
