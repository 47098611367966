
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { OrganizationModule } from '@/modules/organization/store'
import { RouteModule } from '@/modules/route/store'

import OrganizationListItem, { IOrganizationListItem, MIN_ORGANIZATION_ITEM_LINE_HEIGHT } from './list-item.vue'

@Component({
  components: {
    OrganizationListItem
  },
  name: 'OrganizationsList'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)

  @Ref() readonly listRef!: { $el: HTMLElement }
  @Ref() readonly listItemRefs!: { $el: HTMLElement }[]

  MIN_VISIBLE_LINES = 7
  MIN_ORGANIZATION_ITEM_LINE_HEIGHT = MIN_ORGANIZATION_ITEM_LINE_HEIGHT

  get organizationItems (): IOrganizationListItem[] {
    return this.organizationModule.organizations
      .map((o) => ({
        org: o,
        permission: this.organizationModule.organizationPermission(o)
      }))
      .sort((a, b) => a.org.name.localeCompare(b.org.name))
  }

  createOrganization () {
    this.$router.push({
      query: {
        organization_create_dialog: '1'
      }
    })

    this.$emit('close')
  }

  goToOrganizationDocs () {
    window.open('https://docs.icepanel.io/features/organizations', '_blank')
  }
}
