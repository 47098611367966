
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'

import { OrganizationModule } from '../store'

interface IInvite {
  email: string
  expired: boolean
  expiresAt: string
  icon: string
  id: string
  permission: string
}

@Component({
  name: 'OrganizationUserInvitesList'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() scrollRef!: HTMLElement

  resendingInviteId: string | null = null
  revokingInviteId: string | null = null

  scrollYVisible = false

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get organizationUserInvites () {
    return this.organizationModule.organizationUserInvites
  }

  get permissionTypes (): Record<PermissionType, { icon: string, text: string }> {
    return {
      admin: {
        icon: '$fas-user-cog',
        text: 'Admin'
      },
      billing: {
        icon: '$fas-credit-card',
        text: 'Billing'
      },
      read: {
        icon: '$fas-eye',
        text: 'Viewer'
      },
      write: {
        icon: '$fas-user-edit',
        text: 'Editor'
      }
    }
  }

  get invites () {
    return this.organizationUserInvites
      .filter(o => !o.revokedAt && !o.usedAt)
      .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
      .map((o): IInvite => ({
        email: o.email,
        expired: new Date(o.expiresAt) < new Date(),
        expiresAt: o.expiresAt,
        icon: this.permissionTypes[o.permission].icon,
        id: o.id,
        permission: this.permissionTypes[o.permission].text
      }))
  }

  async resendInvite (organizationUserInviteId: string) {
    const organizationUserInvite = this.organizationUserInvites.find(o => o.id === organizationUserInviteId)

    if (this.resendingInviteId || !organizationUserInvite) {
      return
    }

    try {
      this.resendingInviteId = organizationUserInviteId

      await this.organizationModule.organizationUserInviteRevoke({
        organizationId: this.currentOrganization.id,
        organizationUserInviteId
      })
      await this.organizationModule.organizationUserInviteCreate({
        invite: {
          email: organizationUserInvite.email,
          expiresAt: organizationUserInvite.expiresAt,
          landscapePermissions: organizationUserInvite.landscapePermissions,
          permission: organizationUserInvite.permission
        },
        organizationId: this.currentOrganization.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.resendingInviteId = null
    }
  }

  async revokeInvite (organizationUserInviteId: string) {
    if (this.revokingInviteId) {
      return
    }

    try {
      this.revokingInviteId = organizationUserInviteId

      await this.organizationModule.organizationUserInviteRevoke({
        organizationId: this.currentOrganization.id,
        organizationUserInviteId
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.revokingInviteId = null
    }
  }
}
