
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import { Organization } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import getColor from '@/helpers/get-color'
import getInitials from '@/modules/user/helpers/get-initials'

type OrganizationAvatarSize = 'small' | 'medium'

@Component({
  name: 'OrganizationAvatar'
})
export default class extends Vue {
  @Prop() readonly organization!: Organization
  @Prop({ default: 'small' }) readonly size!: OrganizationAvatarSize

  get avatarClasses () {
    return this.size === 'small' ? 'tw-h-4.5 tw-w-4.5 tw-text-2xs tw-leading-4' : 'tw-h-6 tw-w-6 tw-text-base tw-leading-6'
  }

  color (id?: string) {
    return id ? TAG_COLOR_ACTIVE[getColor(id)] : undefined
  }

  initials (name?: string) {
    const noEmojisName = name?.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, '').trim()
    return noEmojisName ? getInitials(noEmojisName) : undefined
  }
}
