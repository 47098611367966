
import { FormController, validationRules } from '@icepanel/app-form'
import { Landscape, OrganizationUser, PermissionType } from '@icepanel/platform-api-client'
import { addDays } from 'date-fns'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import contains from '@/helpers/contains'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationUserInvite'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  loadingBillingLink = false

  inviteEmailModel = ''

  formController = new FormController({
    initialModel: {
      userEmail: [] as string[],
      userLandscapePermissions: undefined as OrganizationUser['landscapePermissions'],
      userPermission: 'write' as PermissionType
    },
    validationRules: {
      userEmail: [
        ...validationRules.exists,
        ...validationRules.emails
      ],
      userLandscapePermissions: validationRules.exists,
      userPermission: [
        ...validationRules.exists,
        o => this.validateSeats(o)
      ]
    }
  })

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationUsers () {
    if (this.organizationModule.organizationUsers[this.currentOrganization.id]) {
      return Object.entries(this.organizationModule.organizationUsers[this.currentOrganization.id]).map(([id, o]) => ({
        ...o,
        id
      }))
    }
  }

  get usedEditorSeats () {
    return this.organizationUsers?.filter(o => contains(o.permission, ['write', 'admin'])).length || 0
  }

  get totalEditorSeats () {
    return this.currentOrganization.seats
  }

  get emptyEditorSeats () {
    return this.organizationUsers ? Math.max(0, this.totalEditorSeats - this.usedEditorSeats) : 0
  }

  get pendingEditorSeats () {
    let count = 0
    if (this.formController.model.userEmail && this.formController.model.userPermission !== 'billing' && this.formController.model.userPermission !== 'read') {
      count += this.formController.model.userEmail.length
    }
    return count
  }

  get userEmailValidation () {
    return this.formController.model.userEmail.map(o => validationRules.email.map(v => v(o)).find(v => v !== false))
  }

  get userEmailValidationSuccessful () {
    return this.formController.model.userEmail.length && (!this.userEmailValidation.length || this.userEmailValidation.every(o => o === true))
  }

  get permissionTypes (): { id: PermissionType, icon: string, text: string, value: string }[] {
    return [
      {
        icon: '$fas-user-edit',
        id: 'write',
        text: 'Editor',
        value: 'write'
      },
      {
        icon: '$fas-eye',
        id: 'read',
        text: 'Viewer',
        value: 'read'
      },
      {
        icon: '$fas-user-cog',
        id: 'admin',
        text: 'Admin',
        value: 'admin'
      },
      {
        icon: '$fas-credit-card',
        id: 'billing',
        text: 'Billing',
        value: 'billing'
      }
    ]
  }

  get permissionInfo (): Record<PermissionType, { text: string, icon: string }[]> {
    return {
      admin: [
        {
          icon: '$fas-users',
          text: 'Add and remove team members'
        },
        {
          icon: '$fas-trash',
          text: 'Remove landscapes'
        },
        {
          icon: '$fas-link',
          text: 'Edit and set up integrations'
        }
      ],
      billing: [
        {
          icon: '$fas-users',
          text: 'Add and remove team members'
        },
        {
          icon: '$fas-credit-card',
          text: 'Update payment information'
        }
      ],
      read: [
        {
          icon: '$fas-eye',
          text: 'View up to date designs & docs'
        },
        {
          icon: '$fas-question',
          text: 'Add questions and ideas'
        },
        {
          icon: '$fas-thumbs-down',
          text: 'Mark designs as inaccurate'
        }
      ],
      write: [
        {
          icon: '$fas-pencil-alt',
          text: 'Design & document together'
        },
        {
          icon: '$fas-people-arrows',
          text: 'Add and share their own expertise'
        },
        {
          icon: '$custom-solid-user-hard-hat',
          text: 'Easily maintain and update docs'
        }
      ]
    }
  }

  get landscapes () {
    return this.landscapeModule.landscapes
  }

  get landscapePermissions () {
    let userLandscapePermissions = 'All (default)'

    const value = this.formController.model.userLandscapePermissions
    if (value) {
      const landscapes = Object
        .entries(value)
        .filter(([, permission]) => permission)
        .map(([id]) => this.landscapes.find(o => o.id === id))
        .filter((o): o is Landscape => !!o)
      if (landscapes.length) {
        userLandscapePermissions = landscapes.map(o => o.name).join(', ')
      } else {
        userLandscapePermissions = 'None'
      }
    }

    return userLandscapePermissions
  }

  created () {
    this.formController.submitHandler = async model => {
      await Promise.all(model.userEmail.map(o => this.createUserInvite(o, model.userPermission, model.userLandscapePermissions)))

      this.$emit('invite')
    }
  }

  mounted () {
    analytics.organizationUserInviteScreen.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : [],
      organizationId: [this.currentOrganization.id]
    })

    this.formController.resetStatus()
    this.formController.resetModel()

    this.formController.on('success', () => {
      this.formController.resetModel()

      setTimeout(() => {
        this.formController.resetStatus()
      }, 3000)
    })
  }

  reset () {
    this.formController.resetModel()
  }

  validateSeats (permission: PermissionType) {
    if (permission === 'admin' || permission === 'write') {
      if (this.pendingEditorSeats <= this.emptyEditorSeats) {
        return true
      } else {
        return this.emptyEditorSeats === 1 ? '1 editor left' : `${this.emptyEditorSeats} editors left`
      }
    } else {
      return true
    }
  }

  async addInviteEmail () {
    // wait for clipboard contents
    await new Promise(resolve => setTimeout(resolve))

    const commaSeperatedEmails = this.inviteEmailModel
      .toLowerCase()
      .split(/,/gm)
      .map(o => /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.exec(o)?.[0] ?? '')
      .filter(o => o && !this.formController.model.userEmail.includes(o))

    if (commaSeperatedEmails.length) {
      commaSeperatedEmails.forEach(o => this.formController.model.userEmail.push(o))
      this.inviteEmailModel = ''
    }
  }

  removeInviteEmail (email?: string, event?: KeyboardEvent) {
    if (email) {
      this.formController.model.userEmail = this.formController.model.userEmail.filter(o => o !== email)
    } else if (!this.inviteEmailModel && this.formController.model.userEmail.length) {
      this.inviteEmailModel = this.formController.model.userEmail[this.formController.model.userEmail.length - 1]
      this.formController.model.userEmail = this.formController.model.userEmail.slice(0, -1)
      event?.preventDefault()
    }
  }

  async createUserInvite (email: string, permission: PermissionType, landscapePermissions?: Record<string, boolean>) {
    const organizationUserInvite = await this.organizationModule.organizationUserInviteCreate({
      invite: {
        email,
        expiresAt: addDays(new Date(), 7).toISOString(),
        landscapePermissions,
        permission
      },
      organizationId: this.currentOrganization.id
    })

    analytics.organizationUserInviteCreate.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : undefined,
      organizationId: [this.currentOrganization.id],
      userEmail: organizationUserInvite.email,
      userExists: !!organizationUserInvite.usedAt,
      userPermission: permission
    })
  }

  async editBilling () {
    try {
      if (this.loadingBillingLink) {
        return
      }
      this.loadingBillingLink = true

      const returnUrl = new URL(window.location.href)
      returnUrl.searchParams.set('billing_organization', this.currentOrganization.id)

      const links = await this.organizationModule.organizationBillingLinkCreate({
        organizationId: this.currentOrganization.id,
        returnUrl: returnUrl.toString()
      })

      analytics.organizationBillingLink.track(this, {
        organizationId: [this.currentOrganization.id]
      })

      await new Promise(resolve => setTimeout(resolve, 1000))

      window.location.href = links.updateSubscriptionPlanUrl || links.url
    } catch (err: any) {
      this.loadingBillingLink = false
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    }
  }

  updateLandscapePermissions (landscapeId?: string) {
    if (landscapeId) {
      const value = this.formController.model.userLandscapePermissions
      if (value) {
        if (value[landscapeId]) {
          Vue.delete(value, landscapeId)
        } else {
          Vue.set(value, landscapeId, true)
        }
      } else {
        this.formController.model.userLandscapePermissions = this.landscapes.reduce<Record<string, boolean>>((p, c) => {
          if (c.id === landscapeId) {
            return p
          } else {
            return {
              ...p,
              [c.id]: true
            }
          }
        }, {})
      }
    } else if (this.formController.model.userLandscapePermissions) {
      this.formController.model.userLandscapePermissions = undefined
    } else {
      this.formController.model.userLandscapePermissions = {}
    }
  }
}
