
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'
import UserInvite from './user-invite.vue'
import UserInvitesList from './user-invites-list.vue'

@Component({
  components: {
    Dialog,
    UserInvite,
    UserInvitesList
  },
  name: 'OrganizationUserInviteDialog'
})
export default class extends Vue {
  @Ref() readonly userDialog!: UserInvite

  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get landscapesListStatus () {
    return this.landscapeModule.landscapesListStatus
  }

  get organizationUserInvitesListStatus () {
    return this.organizationModule.organizationUserInvitesListStatus
  }

  get organizationUsers () {
    if (this.organizationModule.organizationUsers[this.currentOrganization.id]) {
      return Object.entries(this.organizationModule.organizationUsers[this.currentOrganization.id]).map(([id, o]) => ({
        ...o,
        id
      }))
    }
  }

  get organizationUserInvites () {
    return this.organizationModule.organizationUserInvites.filter(o => !o.revokedAt && !o.usedAt)
  }

  open () {
    if (this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUsersList(this.currentOrganizationId)
    }
    if (this.currentOrganizationId && this.organizationModule.organizationUserInvitesListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUserInvitesListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUserInvitesList(this.currentOrganizationId)
    }
    if (this.currentOrganizationId && this.landscapesListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.landscapesListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.landscapeModule.landscapesList(this.currentOrganizationId)
    }
  }

  close () {
    this.userDialog.reset()
  }

  opened () {
    analytics.organizationUserInviteDialog.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : undefined,
      organizationId: [this.currentOrganization.id]
    })
  }
}
