
import { Organization, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'

import OrganizationAvatar from './avatar.vue'

export interface IOrganizationListItem {
  org: Organization
  permission: PermissionType | null
}

export const MIN_ORGANIZATION_ITEM_LINE_HEIGHT = 52

@Component({
  components: {
    OrganizationAvatar
  },
  name: 'OrganizationsListItem'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)

  @Prop() readonly item!: IOrganizationListItem

  MIN_ORGANIZATION_ITEM_LINE_HEIGHT = MIN_ORGANIZATION_ITEM_LINE_HEIGHT

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find((o) => o.id === this.currentLandscapeId)!
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape.organizationId
  }

  get permissionsTitle () {
    return (permission: PermissionType) => {
      switch (permission) {
        case 'read':
          return 'Viewer'
        case 'write':
          return 'Editor'
        case 'admin':
          return 'Admin'
        case 'billing':
          return 'Billing'
      }
    }
  }

  goToManageOrganization (organizationId: string) {
    this.$emit('close')
    this.$router.push({
      name: 'organization-manage-overview',
      params: {
        organizationId
      }
    })
  }
}
